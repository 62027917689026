import React from 'react';
import _ from 'lodash';

export const MessageContext = React.createContext(null);
const  MessageCenter = ({children}) => {
  const [messages, setMessages] = React.useState({});
  const registerMesssage = (name, callback) => {
    if (_.isUndefined(messages[name])) {
      messages[name] = [];
      messages[name].push(callback);
      return;
    }
    messages[name].push(callback);
  };
  const sendMessage = (message,value) => {
    if (!messages[message])
      return;
    for (let i=0; i<messages[message].length; i++) {
      messages[message][i]?.(value);
    }
  }
  return (
    <MessageContext.Provider value={{registerMesssage, sendMessage}}>
      {children}
    </MessageContext.Provider>
  );
};
export default  MessageCenter;