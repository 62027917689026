import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { isUndefined, isNull } from 'lodash';

const localLanguage = !isUndefined(localStorage.getItem('i18nextLng'))
  ? !isNull(localStorage.getItem('i18nextLng'))
    ? localStorage.getItem('i18nextLng')
    : 'zh'
  : 'zh';





export default i18n;
