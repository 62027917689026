import { filter } from 'lodash';
import { getFileType } from './fileType';


export 	function imgTagAddStyle(htmlstr) {
  if (!htmlstr) {
    return '';
  }
  //正则匹配所有img标签
  //var regex0 = new RegExp("(i?)(\<img)([^\>]+\>)","gmi");
  //正则匹配不含style="" 或 style='' 的img标签
  const regex1 = new RegExp("(i?)(\<img)(?!(.*?style=['\"](.*)['\"])[^\>]+\>)","gmi");
  //给不含style="" 或 style='' 的img标签加上style=""
  htmlstr = htmlstr.replace(regex1, "$2 style=\"\"$3");
  //正则匹配含有style的img标签
  const regex2 = new RegExp("(i?)(\<img.*?style=['\"])([^\>]+\>)", "gmi");
  //在img标签的style里面增加css样式(这里增加的样式：display:block;max-width:100%;height:auto;border:5px solid red;)
  htmlstr = htmlstr.replace(regex2, "$2max-width:100%;height:auto;$3");
  return htmlstr;
}

export function getDocumentThumb(document) {
  const { type, thumbnail, asset } = document;

  if (type==='product'||type==='catalog') {
    let mines = filter(document.mime, {mimePurpose:'thumbnail'});
    return mines[0]?.mimeSource?.[0]?.['#text'];
  }

  if (thumbnail) return thumbnail;

  if (type === 'weblink') {
    return `/fileTypes/${type}.png`;
  }

  if (type !== 'file') {
    return `/fileTypes/${type}.svg`;
  }

  const fileType = getFileType(asset.filename);
  return fileType === 'photo'
    ? asset.url
    : `/fileTypes/${fileType}.svg`;
}

export function getAppDocumentThumb(document) {
  const { type, thumbnail, asset } = document;
  if (thumbnail) return thumbnail;

  if (type === 'weblink') {
    return `/assets/${type}.png`;
  }

  if (type !== 'file') {
    return `/assets/${type}.svg`;
  }

  const fileType = getFileType(asset.filename);
  return fileType === 'photo'
    ? asset.url
    : `/assets/${fileType}.svg`;
}

export function queryParse(query) {
  query = query.substring(query.indexOf('?')+1);
  let array = query.split("&")
  let params = {};
  array.forEach(item => {
    let key = item.substring(0,item.indexOf('='));
    if(key==='')
      return;
    let value= decodeURIComponent(item.substring(item.indexOf('=')+1));
    params[key]= value;
  });
  return params;
}