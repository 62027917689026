import React from 'react';
import { WebInfo } from './ActiveContainer';

export const UserContext = React.createContext(null);

export const TenantContext = React.createContext(null);

export const TeammessageContext = React.createContext(null);

export const WebSiteContext = React.createContext<WebInfo>(null);

export const DeviceType = React.createContext<'mobile' | 'computer'>('computer');

export const InnerUserContext = React.createContext(null);

export const ThemeContext = React.createContext(null);

export const IframeLoginContext = React.createContext(null);


