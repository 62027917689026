import _, { difference, get, isEmpty, omit, pick, union } from 'lodash';
import { AnyAction } from 'redux';
import { useSelector } from 'react-redux';
import { handleActions } from 'redux-actions';
import { call, takeEvery, takeLeading } from 'redux-saga/effects';

import { sanitize } from '@ivymobi/orbit-domain';

import {
  createPromiseAction,
  rejectPromiseAction, resolvePromiseAction,
} from '../util/redux-saga-promise';
import {
  ContextUser, Document, Share, Tenant, TenantActionMeta
} from '../types';
import { setBrowseTimeAction, setEXBrowseTimeAction } from './document';

const defaultShares = {ids: [], data: {}};

/**
 * 获取用户自己的分享列表，
 */
export const useShares = ({user, tenant}) => useSelector(
  (state) => get(state, [user.user.id, 'tenants', tenant.id, 'shares'], defaultShares)
);

export const useShare = ({user, tenant, showcaseId}) => useSelector((state) => {
  return get(state, [user.id, 'tenants', tenant.id, 'shares', 'data', showcaseId], {});
});
export const getChildShareAction = createPromiseAction(
  'user_share/GET_CHILD_SHARE',
  (data: {id: string}) => pick(data, ['id']),
);

export function* watchGetChildShare(api) {
  yield takeLeading(getChildShareAction, function* (action) {
    try {
      const share = yield call(api.getJSON, {
        url: `tenants/${action.payload.id}/selAppletShareInfoBySid`
      });
      yield call(resolvePromiseAction, action, share);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
// : (data: {user: User, tenant: Tenant}) => any
export const getSharesAction = createPromiseAction(
  'share/LIST',
  () => ({}),
  (data: {user: ContextUser, tenant: Tenant}) => pick(data, ['user', 'tenant'])
);

function* watchGetShares(api) {
  yield takeEvery(getSharesAction, function* (action) {
    const { meta: { user: {token, user}, tenant } } = action;
    try {
      const res = yield call(api.getJSON, {
        url: `tenants/${tenant.id}/members/${user.id}/shares`,
        token
      });
      const shares = yield sanitize.shareList(res);
      yield call(resolvePromiseAction, action, shares, {user, tenant});
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

export const getLanuageJsonAction = createPromiseAction(
  'share/GET_LANGUAGE_JSON',
  () => ({}),
  (data) => pick(data, [])
);

export function* watchLanguageJson(api) {
  yield takeEvery(getLanuageJsonAction, function* (action) {
    try {
      const res = yield call(api.getJSON, {
        url: `tenants/getOpsLanuages`,
      });
      yield call(resolvePromiseAction, action,res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

export const getTenantLanuageAction = createPromiseAction(
  'share/GET_TENANT_LANGUAGE_JSON',
  () => ({}),
  (data: { tenant: Tenant}) => pick(data, [ 'tenant'])
);

export function* watchTenantLanguage(api) {
  yield takeEvery(getTenantLanuageAction, function* (action) {
    const { meta: { tenant } } = action;

    try {
  
      const res = yield call(api.getJSON, {
        url: `tenants/${tenant.id}/getTenantLanguages`,
      });
      yield call(resolvePromiseAction, action,res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

// webshowcase分享小程序二维码
export const getWXCodeAction = createPromiseAction(
  'user_share/GET_WXCODE',
  ({showcaseId}) => ({showcaseId}),
  (data: {tenant: Tenant}) => pick(data, ['tenant'])
);
function* watchGetWXCode(api) {
  yield takeLeading(getWXCodeAction, function* (action) {
    const {payload: {showcaseId}, meta: {tenant}} = action;
    try {
      const res = yield call(api.getBlob, {
        url: `tenants/${tenant.id}/showcases/${showcaseId}/wxcode`,
      });

      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
export const setHeadImg = createPromiseAction(
  'user/SET_HEAD_IMG',
  data => pick(data, ['avatarUrl']),
  data => pick(data, ['user']),
);
function* watchsetHeadImg(api) {
  yield takeEvery<AnyAction>(setHeadImg, function*(action) {

    const {
      meta: { user },
      payload: { avatarUrl }
    } = action;
    try {
      const res = yield call(api.postJSON, {
        url: `user/updateWechatHeadSculpture`,
        token: user.token,
        body:{ avatarUrl }
      });
      yield resolvePromiseAction(action, res);
    } catch (e) {
      yield rejectPromiseAction(action, e);
    }
  });
}
//: (data: {share: any, user: User, tenant: Tenant}) => any
export const createShareAction = createPromiseAction(
  'share/CREATE',
  (data: {share: Share}) => data.share,
  (data: {user: ContextUser, tenant: Tenant}) => pick(data, ['user', 'tenant'])
);

function* watchCreateShare(api) {
  yield takeEvery(createShareAction, function* (action) {
    const { payload, meta: { user: {token, user}, tenant } } = action;
    try {
      const res = yield call(api.postJSON, {
        url: `tenants/${tenant.id}/members/${user.id}/shares`,
        token,
        body: {tenantId: tenant.id, ...payload},
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

/**
 * 取消分享。
 * @argument id 分享id
 */
export const revokeShareAction = createPromiseAction(
  'share/REVOKE',
  (data: {id: string}) => data.id,
  (data: {user: ContextUser, tenant: Tenant}): TenantActionMeta => pick(data, ['user', 'tenant'])
);

function* watchRevokeShare(api) {
  yield takeLeading(revokeShareAction, function* (action) {
    const { payload, meta: { user: {token, user}, tenant } } = action;
    try {
      yield call(api.deleteJSON, {
        url: `tenants/${tenant.id}/shares/${payload}`,
        token: token,
      });
      yield call(
        resolvePromiseAction, action, payload, {user, tenant}
      );
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

export const getShareLeadAction = createPromiseAction(
  'share/GET_LEAD',
  (data: {id: string, showcaseId: string}) => pick(data, ['showcaseId', 'id']),
  (data: {user: ContextUser, tenant: Tenant}): TenantActionMeta => pick(data, ['user', 'tenant'])
);

function* watchGetShareLead(api) {
  yield takeEvery(getShareLeadAction, function* (action) {
    const { payload, meta: {tenant, user: {token, user}} } = action;
    try {
      const res = yield call(api.getJSON, {
        url: `tenants/${tenant.id}/showcases/${payload.showcaseId}/leads/${payload.id}`,
        token,
      });

      yield call(resolvePromiseAction, action, res,
        { user, tenant, campaignId: payload.showcaseId }
      );
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

export const reconfigCampaignAction = createPromiseAction(
  'share/RECONFIG_CAMP',
  (data: {id: string, settings: unknown}) => pick(data, ['settings', 'id']),
  (data: {user: ContextUser, tenant: Tenant}): TenantActionMeta => pick(data, ['user', 'tenant'])
);

function* watchReconfigCampaign(api) {
  yield takeLeading(reconfigCampaignAction, function* (action) {
    const { payload: {id, settings}, meta: { user: {token, user}, tenant } } = action;
    try {
      let campaign = yield call(api.putJSON, {
        url: `tenants/${tenant.id}/campaigns/${id}/settings`,
        token,
        body: settings
      });
      campaign = yield sanitize.share(campaign);
      yield call(resolvePromiseAction, action, campaign, {user, tenant});
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
export const reconfigShareAction = createPromiseAction(
  'share/RECONFIG',
  (data: {id: string, settings: unknown}) => pick(data, ['settings', 'id']),
  (data: {user: ContextUser, tenant: Tenant}): TenantActionMeta => pick(data, ['user', 'tenant'])
);

function* watchReconfigShare(api) {
  yield takeLeading(reconfigShareAction, function* (action) {
    const { payload: {id, settings}, meta: { user: {token, user}, tenant } } = action;
    try {
      let campaign = yield call(api.putJSON, {
        url: `tenants/${tenant.id}/shares/${id}`,
        token,
        body: settings
      });
      campaign = yield sanitize.share(campaign);
      yield call(resolvePromiseAction, action, campaign, {user, tenant});
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
// 客户获取分享
export const getUserShareAction = createPromiseAction(
  'user_share/VIEW',
  (data: {id: string}) => pick(data, ['id']),
);

export function* watchGetUserShare(api) {
  yield takeLeading(getUserShareAction, function* (action) {
    try {
      const share = yield call(api.getJSON, {
        url: `shares/${action.payload.id}`
      });
      yield call(resolvePromiseAction, action, share);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
//最近搜索
export const getUserShareSearchAction = createPromiseAction(
  'user_share/GET_SEARCH',
  () => ({}),
  data => pick(data, ['searchText', 'user'])
);
export function* watchGetSearch(api) {
  yield takeLeading<AnyAction>(getUserShareSearchAction, function* (action) {
    const {searchText, user} = action.meta;
    try {
      const res = yield call(api.getJSON, {
        url: `user/shares/received?searchFilterText=${searchText}`,
        token: user.token,
      });
      const searchList = yield sanitize.shareList(res);
      yield call(resolvePromiseAction, action, searchList);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}


export const setUserShareCollectionAction = createPromiseAction(
  'user_share/SET_COLLECTION',
  () => ({}),
  data => pick(data, ['shareId', 'user', 'payload'])
);
export function* watchSetCollection(api) {
  yield takeLeading<AnyAction>(setUserShareCollectionAction, function* (action) {
    const {shareId, user, payload} = action.meta;
    try {
      const res = yield call(api.postJSON, {
        url: `user/shares/${shareId}/collection`,
        token: user.token,
        body:payload,
      });

      const sharelist = yield sanitize.shareList(res);
      yield call(resolvePromiseAction, action, sharelist);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
export const getUserShareListAction = createPromiseAction(
  'user_share/GET_SHARELIST',
  () => ({}),
  data => pick(data, ['user','page','pageSize'])
);
export function* watchGetShareList(api) {
  yield takeLeading<AnyAction>(getUserShareListAction, function* (action) {

    const { user,page,pageSize } = action.meta;
    try {
      const res = yield call(api.getJSON, {
        url: `user/shares/received?startCount=${page*pageSize+(page>0?1:0)}&endCount=${(page+1)*pageSize}`,
        token: user.token,
      });
      const sharelist = yield sanitize.shareList(res);
      yield call(resolvePromiseAction, action, sharelist);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
export const getUserShareDocumentsAction = createPromiseAction(
  'user_share/GET_DOCUMENTS',
  (data: {id?: string, shareId: string, searchText: string, tenant: object}) => pick(data, ['shareId', 'id', 'searchText', 'tenant'])
);

export function* watchGetUserShareDocuments(api) {
  yield takeEvery(getUserShareDocumentsAction, function* (action) {
    const {payload: {id, shareId, searchText, tenant}} = action;
    try {
      const res = yield call(api.getJSON, {
        url: isEmpty(id) ?
          `shares/${shareId}/documents`+(searchText?.length>0?'?nameFilter='+searchText:'') :
          `shares/${shareId}/documents/${id}${(searchText?.length > 0 ? '?nameFilter='+searchText : '')}`
      });
      // if()
      let docs = res;//yield sanitize.documentList(res);
      const folderImg = _.get(tenant, ['info', 'companyCoverCurrentUrl'], null);
      if(folderImg){
        docs?.forEach((item) => {
          if (item.type === 'folder' && _.isNil(item.thumbnail)) {
            item.thumbnail = folderImg;
          }
        });
      }

      yield call(resolvePromiseAction, action, docs);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}


export const getOutLibDocumentsAction = createPromiseAction(
  'user_share/GET_OUTLIB_DOCUMENTS',
  (data: {id?: string, tenantId: string, type?: string}) => pick(data, ['tenantId', 'id', 'type'])
);
export function* watchGetOutLibDocuments(api) {
  yield takeEvery(getOutLibDocumentsAction, function* (action) {
    const {payload: {id, tenantId, type}} = action;
    try {
      const res = yield call(api.getJSON, {
        url: isEmpty(id) ?
        (type ? `tenants/${tenantId}/getExtDocumentsByType?type=${type}` : `tenants/${tenantId}/Extdocuments`) :
        `tenants/${tenantId}/Extdocuments?parentid=${id}`
      });
      // if()
      // const docs = yield sanitize.documentList(res);
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

//获取sample公司资料
export const getICatalogDocumentsAction = createPromiseAction(
  'user_share/GET_ICATALOG_DOCUMENTS',
  (data: {id?: string, tenantId: string, type?: string, userId?:string, tenant: any}) => pick(data, ['tenantId', 'id', 'type', 'userId', 'tenant'])
);
export function* watchGetICatalogDocuments(api) {
  yield takeEvery(getICatalogDocumentsAction, function* (action) {
    const {payload: {id, tenantId, type, userId, tenant}} = action;
    let url = isEmpty(id) ? (`tenants/${tenantId}/selExtdocuments`+(userId?`?userId=${userId}`:'')) : (`tenants/${tenantId}/selExtdocuments?parentid=${id}`+(userId?`&userId=${userId}`:''))

    try {
      const res = yield call(api.getJSON, {
        url: url
      });
      // if()
      let docs = yield sanitize.documentList(res);
      const folderImg = _.get(tenant, ['companyCoverCurrentUrl'], null);
      docs.forEach((item) => {
        if (item.type === 'folder' && _.isNil(item.thumbnail)) {
          item.thumbnail = folderImg;
        }
      });
      yield call(resolvePromiseAction, action, docs);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
//检测是否已经关注公公众号

export const getECodeStatusAction = createPromiseAction(
  'user_share/GET_ECODE_STATUS_ACTION',
  data => data.payload,
  data => pick(data, [ 'user']),
);
export function* watchGetECodeStatus(api) {
  yield takeEvery(getECodeStatusAction, function* (action) {
    const {
      payload,
      meta: { user }
    } = action;    try {
      const res = yield call(api.postJSON, {
        url:  `wechat/verifyWechatAccessQCode/${payload.tenantId}`,
        token:user?.token
      });
      // if()
      // const docs = yield sanitize.documentList(res);
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
//获取关注公众号的二维码
export const getECodeAction = createPromiseAction(
  'user_share/GET_ECODE__ACTION',
  data => data.payload,
  data => pick(data, [ 'user']),
);
export function* watchGetECode(api) {
  yield takeEvery(getECodeAction, function* (action) {
    const {
      payload,
      meta: { user }
    } = action;    try {
      const res = yield call(api.postJSON, {
        url:  `wechat/getSubscriptionNumberWechat/${payload.tenantId}`,
        token:user?.token
      });
      // if()
      // const docs = yield sanitize.documentList(res);
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}


//获取更新记录
export const getICatalogUpdateDocumentsAction = createPromiseAction(
  'user_share/GET_ICATALOG_UPDATE_DOCUMENTS',
  (data: {tenantId: string}) => pick(data, ['tenantId'])
);
export function* watchGetUpdateICatalogDocuments(api) {
  yield takeEvery(getICatalogUpdateDocumentsAction, function* (action) {
    const {payload: { tenantId}} = action;
    try {
      const res = yield call(api.getJSON, {
        url:  `tenants/${tenantId}/uploadSampleHis`
      });
      // if()
      // const docs = yield sanitize.documentList(res);
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

// 全局搜索接口
export const shareSearchSolrAction = createPromiseAction(
  'user_share/SOLR_SEARCH',
  (data: { payload: {namefiter: string, type: number, title: string} }) => data.payload,
  (data: { user: ContextUser, tenant: Tenant }) => ({ user: data.user, tenant: data.tenant })
);
export function* watchShareSolrSearch(api) {
  yield takeLeading(shareSearchSolrAction, function* (action) {
    let url = `tenants/${action.meta.tenant.id}/getFullTextSearching?nameFilter=${action.payload.namefiter}&type=${action.payload.type}`;
    if (!isEmpty(action.payload.title)) {
      url += `&title=${action.payload.title}`;
    }
    try {
      const res = yield call(api.getJSON, {
        url: url,
        // token: action.meta.user.token,
        // body: action.payload,
      });

      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

// 获取分享单页图片
export const getPdfStandImageAction = createPromiseAction (
  'user_share/Get_PDF_STAND_Image',
  (data: {id?: string,tenantId?: string,pageIndex?: string}) => pick(data, ['id','tenantId','pageIndex']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);

export function* getPdfStandImage(api) {
  yield takeEvery(getPdfStandImageAction, function*(action) {
    const {payload: {id,tenantId,pageIndex}, meta: {user: {token, user}, tenant}} = action;

    try {
      const res = yield call(api.postJSON, {
        url: `tenants/pdfImageByPage`,
        body:{id,tenantId,pageIndex

        }
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }

  });
}

// 获取pdf
export const getPdfImagesAction = createPromiseAction (
  'user_share/GetDocImage',
  (data: {fileid: string}) => pick(data, ['fileid'])
);

export function* pdfImageList(api) {
  yield takeEvery(getPdfImagesAction, function*(action) {
    const {payload: {fileid}} = action;
    try {
      const res = yield call(api.getJSON, {
        url: `shares/document/${fileid}/images`,
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }

  });
}

export interface UserShareEvent {
  type: 'view'|'share'|'like'|'download';
  user: ContextUser;
  share: Share;
  document?: Document;
  isCampaign?: boolean;
  detail?: {[key: string]: unknown}
}

/**
 * @description 如果没有登录需要手机号和验证码和信息一起提交，
 * 如果已经登录了会调用 userShareEvent
 */
export const userAuthAndShareEventAction = createPromiseAction(
  'shares/DOCUMENT_EVENT',
  data => data.payload, // countryCode?, phone?, code?, message?, title ...
  data => pick(data, [ 'user', 'share', 'type', 'document' ]),
);
export function* watchUserAuthAndShareEvent(api, tokenKey) {
  yield takeLeading<AnyAction>(userAuthAndShareEventAction, function* (action) {
    const {
      payload,
      meta: { user, share, document, type }
    } = action;

    try {
      const res = yield call(api.postJSON, {
        url: `shares/${share.id}/documents/${document.id}/${type}`,
        token: user?.token,
        body: payload,
      });
      if (res.token) {
        yield api.setItem(tokenKey, res.token);
      }
      yield resolvePromiseAction(action, res);
    } catch (e) {
      yield rejectPromiseAction(action, e, { notify: { message: e.message } });
    }
  });
}

export const getCustomDocumentAction = createPromiseAction(
  'document/GET_CUSTOM_DOC',
  (data: {id?: string}) => pick(data, ['id']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);

export function* watchGetCustomDocument(api) {
  yield takeEvery(getCustomDocumentAction, function* (action) {
    try {
      const {payload: {id}, meta: {user: {token, user}, tenant}} = action;
      const url = `tenants/${tenant.id}/documents/${id}`;
      const res = yield call(api.getJSON, {
        url,
        token
      });
      let docs = null;

      if (res.type==='catalog'||res.type==='product') {
        docs= [res];
      } else {
        docs = yield sanitize.documentList(res);
      }
      yield call(resolvePromiseAction, action, docs, {user, tenant});
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

export const sendNoFileUrlEmailAction = createPromiseAction(
  'tenant/SEND_N0_TOKEN_FILE_URL_EMAIL',
  (data: { payload: unknown }) => data.payload,
  (data: { user: ContextUser, tenant: Tenant }) => pick(data, ['user', 'tenant'])
);
export function* watchSendNoFileUrlEmail(api) {
  yield takeLeading(sendNoFileUrlEmailAction, function* (action) {
    try {
      const tenants = yield call(api.postJSON, {
        url: `tenants/${action.meta.tenant.id}/sendFileUrlEmail/${action.payload.id}`,
        token: action.meta.user.token,
        body:action.payload
      });
      yield call(resolvePromiseAction, action, tenants, {
        user: action.meta.user.user
      });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

export const userShareEvent = createPromiseAction(
  'shares/EVENT',
  (data: UserShareEvent) => {
    const {user: { user }, share, document} = data;
    return {
      ...pick(data, ['type', 'detail', 'eventId']),
      memberId: share.memberId,
      userId: user?.id,
      docId: document?.id,
      isCampaign: share.contact ? true : false,
      docType : document?.type
    };
  },
  (data: UserShareEvent) => ({
    user: data.user,
    share: data.share,
    isCampaign: data.isCampaign
  })
);

export function* watchUserEvent(api) {
  yield takeEvery(userShareEvent, function* (action) {
    const {payload, meta: {share, user}} = action;
    const isCampaign = payload.isCampaign;
    // 分享分享
    isCampaign ? payload.shareType = share.id : payload.shareType = 'showcase';
    if (payload.docType==='product'|| payload.docType==='article' || payload.docType==='quiz') {
      payload.shareType = payload.docType;
    }
    try {
      const res = yield call(api.postJSON, {
        token: user.token,
        url: `stats/${share.tenantId}/shares/${share.id}/events`,
        body: payload
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

export const getUserLeadProfile = createPromiseAction(
  'shares/leadProfile/GET',
  (data: {user: ContextUser, share: Share}) => pick(data, ['user', 'share']),
);

export function* watchGetUserLeadProfile(api) {
  yield takeEvery(getUserLeadProfile, function* (action) {

    const { payload: {share, user: {token}} } = action;
    const checkURL = _.isEmpty(share?.id) || _.isNil(share?.id) || share?.type==="applet"? `tenants/${share.tenantId}/leads/profile` : `tenants/${share.tenantId}/leads/profile?shareid=${share.id}`;
    try {
      const res = yield call(api.getJSON, {
        url: checkURL,
        token
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

// 浏览时长的--埋点--方法  ----mode-----post事件模板
export function* watchBrowseTime(api) {
  yield takeEvery<AnyAction>(setBrowseTimeAction, function* (action) {
    // debugger
    try {
      const {
        payload: { eventID, time },
        meta: {
          // user: {token},
          tenant
        }
      } = action;
      const res = yield call(api.postJSON, {
        url: `stats/documents/browsing/time/${eventID}`,
        // token,
        body: {
          time: time
        }
      });
      // const doc = yield sanitize.document(res);
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
export function* watchEXBrowseTime(api) {
  yield takeEvery<AnyAction>(setEXBrowseTimeAction, function* (action) {
    // debugger
    try {
      const {
        payload: { eventID, time },
        meta: {
          // user: {token},
          tenant
        }
      } = action;
      const res = yield call(api.postJSON, {
        url: `stats/documents/browsing/time/${eventID}`,
        // token,
        body: {
          time: time
        }
      });
      // const doc = yield sanitize.document(res);
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

// 23.10.31   三菱的改动移植一个下载方法测试。。。

export const searchAction = createPromiseAction(
  'group/DOC_SHARE_SEARCH',
  (data: { payload: unknown }) => data.payload,
  (data: { user: ContextUser, tenant: Tenant }) => ({ user: data.user, tenant: data.tenant })
);
export function* watchSearch(api) {
  yield takeLeading(searchAction, function* (action) {
    try {
      const res = yield call(api.postJSON, {
        url: `tenants/${action.meta.tenant.id}/documents/searchFiles`,
        // token: action.meta.user.token,
        body: action.payload,
      });

      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
// 全局搜索接口
export const searchSolrAction = createPromiseAction(
  'share/SOLR_SEARCH',
  (data: { payload: {namefiter: string, type: number} }) => data.payload,
  (data: { user: ContextUser, tenant: Tenant }) => ({ user: data.user, tenant: data.tenant })
);
export function* watchSolrSearch(api) {
  yield takeLeading(searchSolrAction, function* (action) {
    try {
      const res = yield call(api.getJSON, {
        url: `tenants/${action.meta.tenant.id}/getFullTextSearching?nameFilter=${action.payload.namefiter}&type=${action.payload.type}`,
        // token: action.meta.user.token,
        // body: action.payload,
      });

      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}




export const reducer = handleActions({
  [createShareAction.resolved.toString()]: (state, {payload: share}) => {
    const ids = union([share.id], state.ids);
    const data = {...state.data, [share.id]: share};
    return {ids, data};
  },
  [revokeShareAction.resolved.toString()]: (state, action) => {
    const sid = action.payload;
    const ids = difference(state.ids, [sid]);
    const data = omit(state.data, sid);
    return {ids, data};
  },
  [getSharesAction.resolved.toString()]: (_state, {payload: shares}) => {
    const ids = shares.map((share) => share.id);
    const data = shares.reduce((prev, share) => {
      prev[share.id] = share;
      return prev;
    }, {});
    return {ids, data};
  }
}, defaultShares);

export const watchers = [
  watchGetShares, watchGetUserLeadProfile, pdfImageList,getPdfStandImage,
  watchCreateShare, watchRevokeShare, watchReconfigShare,
  watchGetShareLead, watchReconfigCampaign, watchGetWXCode, watchsetHeadImg,
  watchSearch, watchSolrSearch,
  // watchBrowseTime,
];
