import joi from 'joi';

const idSchema = joi.string();

const assetSchema = joi.object({
  id: idSchema,
  createdAt: joi.date().iso(),
  updatedAt: joi.date().iso(),
});

const documentSchema = joi.object({
  id: idSchema,
  name: joi.string().optional().empty(''),  // name 可能为空？
  type: joi.string().allow('folder', 'file'),
  documents: joi.array().items(idSchema).allow(null).default([]),
  asset: assetSchema.optional(),
  createdAt: joi.date().iso(),
  updatedAt: joi.date().iso(),
});
const webLinkSchema = joi.object({
  id: idSchema,
  name: joi.string().optional(),
  type: joi.string().allow('folder', 'file'),
  documents: joi.array().items(idSchema).allow(null).default([]),
  createdAt: joi.date().iso(),
  updatedAt: joi.date().iso(),
});
const documentListSchema = joi.array().items(documentSchema);

const campaignSchema = joi.object({
  id: idSchema,
  tenantId: idSchema,
  name: joi.string(),
  channel: joi.string(),
  type: joi.string(),
  contact: joi.object(),
  documents: joi.array().items(idSchema),
  validFrom: joi.date().iso().optional(),
  validTo: joi.date().iso().optional(),
  visitorInfo: joi.array().items(joi.string()).allow(null),
  eoi: joi.array().items(joi.string()).allow(null),
  eua: joi.boolean(),
  // acl: joi.object().pattern(joi.string(), joi.boolean()),
  acl: joi.object().allow(null),
  createdAt: joi.date().iso(),
  updatedAt: joi.date().iso(),
});

const campaignListSchema = joi.array().items(campaignSchema);

const shareSchema = joi.object({
  id: idSchema,
  tenantId: idSchema,
  name: joi.string(),
  channel: joi.string(),
  type: joi.string(),
  contact: joi.object(),
  documents: joi.array().items(idSchema),
  validTo: joi.date().iso().allow(null),
  visitorInfo: joi.array().items(joi.string()).allow(null),
  // eoi: joi.array().items(joi.string()).allow(null),
  acl: joi.object().allow(null),
  // acl: joi.object().pattern(joi.string(), joi.boolean()).default({'shareable': true, 'downloadable': true}),
  // acl: joi.object().pattern(joi.string(), joi.boolean()).default({'shareable': true, 'downloadable': true, 'urlstr': ''}),
  createdAt: joi.date().iso(),
  updatedAt: joi.date().iso(),
});

const shareListSchema = joi.array().items(shareSchema);

const statsItemSchema = joi.object({
  total: joi.number().integer().default(0),
  delta: joi.number().integer().default(0)
}).default({total: 0, delta: 0});

const statsItemSchemaWithIdentity = (key) => {
  return {
    [`${key}_visitor`]: statsItemSchema,
    [`${key}_member`]: statsItemSchema,
    [`${key}_leads`]: statsItemSchema,
  };
};

const statsSchema = joi.object({
  storage: statsItemSchema,
  netout: statsItemSchema,
  leads: statsItemSchema,
  view: statsItemSchema,
  like: statsItemSchema,
  share: statsItemSchema,
  visit: statsItemSchema,
  download: statsItemSchema,
  fav: statsItemSchema,
  activitiesView: statsItemSchema,
  visitor_visit: statsItemSchema,
  visitor_view: statsItemSchema,
  visitor_share: statsItemSchema,
  visitor_like: statsItemSchema,
  member_fav: statsItemSchema,
  member_view: statsItemSchema,
  member_share: statsItemSchema,
  member_like: statsItemSchema,
  member_download: statsItemSchema,
  leads_view: statsItemSchema,
  leads_like: statsItemSchema,
  leads_visit: statsItemSchema,
  leads_share: statsItemSchema,
  campaign_like: statsItemSchema,
  campaign_visit: statsItemSchema,
  campaign_share: statsItemSchema,
  campaign_view: statsItemSchema,
  campaign_download: statsItemSchema,
  campaign_visitor_like: statsItemSchema,
  campaign_leads_visit: statsItemSchema,
  campaign_visitor_view: statsItemSchema,
  campaign_visitor_visit: statsItemSchema,
  campaign_visitor: statsItemSchema,
  product_fav_member: statsItemSchema,
  product_share_member: statsItemSchema,
  product_like_visitor: statsItemSchema,
  product_view_leads: statsItemSchema,
  product_view_member: statsItemSchema,
  product_view_visitor: statsItemSchema,
  article_view_member: statsItemSchema,
  article_view_visitor: statsItemSchema,
  document_browsing_leads_visitor:statsItemSchema,
  document_browsing_member:statsItemSchema,
  browsingtime_leads:statsItemSchema,
  browsingtime_member:statsItemSchema,
  campaign_browsingtime:statsItemSchema,
  document_download_leads_visitor:statsItemSchema,
  document_download_member:statsItemSchema,
  document_fav_member:statsItemSchema,
  document_like_leads_visitor:statsItemSchema,
  document_share_member:statsItemSchema,
  document_view_leads_visitor:statsItemSchema,
  fav_member_product:statsItemSchema,
  quiz_view_leads:statsItemSchema,
  quiz_view_member:statsItemSchema,
  quiz_view_visitor:statsItemSchema,
  share_member_product:statsItemSchema,
  view_leads_article:statsItemSchema,
  view_leads_product:statsItemSchema,
  view_member_article:statsItemSchema,
  view_member_product:statsItemSchema,
  view_member_quiz:statsItemSchema,
  document_view_member:statsItemSchema,
  view_leads_quiz:statsItemSchema,






  article_view_leads:statsItemSchema,
  product_product_visitor:statsItemSchema,
  nshare:statsItemSchema,
  ...statsItemSchemaWithIdentity('view'),
  ...statsItemSchemaWithIdentity('share'),
  ...statsItemSchemaWithIdentity('visit'),
  ...statsItemSchemaWithIdentity('download'),
  ...statsItemSchemaWithIdentity('like'),
  ...statsItemSchemaWithIdentity('campaign_view'),
  ...statsItemSchemaWithIdentity('campaign_share'),
  ...statsItemSchemaWithIdentity('campaign_visit'),
  ...statsItemSchemaWithIdentity('campaign_download'),
  ...statsItemSchemaWithIdentity('campaign_like'),
  // ...statsItemSchemaWithIdentity('campaign_visitor_visit'),
  campaign_leads: statsItemSchema,
  ...statsItemSchemaWithIdentity('fav'),
});

const favoriteSchema = joi.object({
  id: idSchema,
  name: joi.string().required(),
  documents: joi.array().items(idSchema),
  createdAt: joi.date().iso(),
  updatedAt: joi.date().iso(),
});

const favoriteListSchema = joi.array().items(favoriteSchema);

const leadSchema = joi.object({
  userId: idSchema,
  shareId: idSchema,
  createdAt: joi.date().iso(),
});
const leadListSchema = joi.array().items(leadSchema);

const options = {
  allowUnknown: true,
};

export const sanitize = {
  weblink:(data) => webLinkSchema.validateAsync(data, options),
  document: (data) => documentSchema.validateAsync(data, options),
  documentList: (data) => documentListSchema.validateAsync(data, options),
  campaign: (data) => campaignSchema.validateAsync(data, options),
  campaignList: (data) => campaignListSchema.validateAsync(data, options),
  share: (data) => shareSchema.validateAsync(data, options),
  shareList: (data) => shareListSchema.validateAsync(data, options),
  stats: (data) => statsSchema.validateAsync(data,options),
  favorite: (data) => favoriteSchema.validateAsync(data, options),
  favoriteList: (data) => favoriteListSchema.validateAsync(data, options),
  lead: (data) => leadSchema.validateAsync(data, options),
  leadList: (data) => leadListSchema.validateAsync(data, options),
};
