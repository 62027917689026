import React, {useContext, useEffect} from 'react';
import {Box, Typography} from '@mui/material';
import {MessageContext} from './MeesageCentter';
import {useTranslation} from 'react-i18next';

const WXDownloadMaskView  =({children}) => {
  const [t] = useTranslation();
  const ref = React.useRef(null);
  const messageCenter = useContext(MessageContext);
  useEffect(() => {
    messageCenter.registerMesssage('MESSAGE_POP_MADSK', callback);
    window.addEventListener('popstate', function(event) {
      if (ref.current) {
        ref.current.style.display = 'none'; // 例如设置为'none'
      }
    });

  }, []);
  const store = {};
  const callback = (value) => {
    const {open, url} = value;

    const _url = window.location;
    console.log('_url========================', _url.href);
    store['url'] = _url.href;
    const donwloadUrl = _url.origin+'/download.html?link='+encodeURIComponent(url);
    history.replaceState('', '', donwloadUrl); // 不刷新页面

    if (open) {
      if (ref.current) {
        ref.current.style.display = open?'block':'none'; // 例如设置为'none'
      }
    }
  };
  useEffect(() => {
    if (ref?.current) {
      ref?.current?.addEventListener('touchstart', (e) => {
        if (ref.current) {
          history.replaceState('', '', store['url']);
          ref.current.style.display = 'none'; // 例如设置为'none'
        }
        e.stopPropagation();
        e.preventDefault();
      });
    }
  }, [ref]);
  return (<>
    <Box  onClick={() => {
      if (ref.current) {
        ref.current.style.display = 'none'; // 例如设置为'none'
        history.replaceState('', '', store['url']);
      }

    }} ref={ref} style={{display:'none', position:'fixed', left:'0px', top:'0px', right:'0px', bottom:'0px', background:'#444343d1', zIndex:9999}}>

      <Box  style={{width:'100%', color:'red', display:'flex', justifyContent:'end', alignItems:'end', paddingRight:'20px', paddingTop:'15px'}}>
        <Box style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', marginBottom:'-13px', paddingRight:'20px'}}>
          <Typography variant='subtitle1' style={{color: 'white'}}>{t('Click ••• and select open in')}</Typography>
          <img src='/images/earth.png' style={{width:'23px', height:'23px', marginLeft:'5px', marginRight:'5px'}} />
          <Typography variant='subtitle1' style={{color: 'white'}}>{t('browser')}</Typography>
        </Box>

        <img src='/images/wxdirect.png' style={{width:'20px', height:'35px'}} />


      </Box>
    </Box>

    {children}
  </>

  );
};
export default WXDownloadMaskView;