import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Snackbar, {
  SnackbarCloseReason, SnackbarProps
} from '@mui/material/Snackbar';
import {
  hideNotificationAction, useNotification
} from '@ivymobi/orbit-core/business/notification';
import { useTranslation } from 'react-i18next';
import { Alert } from '@mui/material';


const Notification: React.FunctionComponent<
  Omit<SnackbarProps, 'open'>
> = props => {
  const { t } = useTranslation();
  const { autoHideDuration = 4000 } = props;

  const dispatch = useDispatch();
  const notification = useNotification();
  const type = notification?.type ?? 'info';

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(!!notification);
  }, [notification]);

  const handleRequestClose = useCallback((
    event?: React.SyntheticEvent<any, Event>,
    reason?: SnackbarCloseReason
  ) => {
    if ((notification?.type !== 'info') && (reason === 'clickaway')) {
      return;
    }
    setOpen(false);
  }, [setOpen, notification?.type]);

  const handleExited = useCallback(() => {
    dispatch(hideNotificationAction());
  }, [dispatch]);

  const hideDuration = notification?.autoHideDuration
    ?? autoHideDuration;

  const message = notification?.message
    ? t(`notification.${notification?.message}`, notification?.message)
    : '';
  if (!open) {
    return null;
  }
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      autoHideDuration={hideDuration}
      TransitionProps={{
        onExited: () => {
          handleExited();
        }
      }}
      onClose={handleRequestClose}
    >
      <Alert
        elevation={6}
        variant='filled'
        severity={type}
        onClose={handleRequestClose}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Notification;
